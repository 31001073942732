/**
 * Created by wangtong on 2021/6/22
 */
import {C} from './base'
// GroupCampListScope 大本营范围
type GroupCampListScope = ""|"suspended"|"recommend"

const GroupCampListScopeCons: C<'All'|'Suspended'|'Recommend', GroupCampListScope> = {
	All: {label: '全部', value: "", hint: ''},
	Suspended: {label: '封禁', value: "suspended", hint: ''},
	Recommend: {label: '推荐', value: "recommend", hint: ''}
}

// GroupBattleMemberListScope 对线群群成员列表范围
type GroupBattleMemberListScope = "" | "red" | "blue" | "player" | "onlooker" | "quit" | "shut_up"

const GroupBattleMemberListScopeCons: C<'All' | 'Red' | 'Blue' | 'Player' | 'Onlooker' | 'Quit' | 'ShutUp', GroupBattleMemberListScope> = {
	All: {label: '全部', value: "", hint: ''},
	Red: {label: '红战队', value: "red", hint: ''},
	Blue: {label: '蓝战队', value: "blue", hint: ''},
	Player: {label: '群玩家', value: "player", hint: ''},
	Onlooker: {label: '围观用户', value: "onlooker", hint: ''},
	Quit: {label: '退出用户', value: "quit", hint: ''},
	ShutUp: {label: '禁言用户', value: "shut_up", hint: ''}
}

// GroupBattleMemberListSortBy 对线群成员列表可排序的字段
type GroupBattleMemberListSortBy =
	"create_time"
	| "update_time"
	| "delete_time"
	| "suspend_expire_time"
	| "join_time"
	| "quit_time"
	| "shut_up_expire_time"

const GroupBattleMemberListSortByCons: C<'CreateTime' | 'UpdateTime' | 'DeleteTime' | 'SuspendExpireTime' | 'JoinTime' | 'QuitTime' | 'ShutUpExpireTime', GroupBattleMemberListSortBy> = {
	CreateTime: {label: '创建时间', value: "create_time", hint: ''},
	UpdateTime: {label: '更新时间', value: "update_time", hint: ''},
	DeleteTime: {label: '删除时间', value: "delete_time", hint: ''},
	SuspendExpireTime: {label: '封禁截止时间', value: "suspend_expire_time", hint: ''},
	JoinTime: {label: '加入时间', value: "join_time", hint: ''},
	QuitTime: {label: '退出时间', value: "quit_time", hint: ''},
	ShutUpExpireTime: {label: '禁言截止时间', value: "shut_up_expire_time", hint: ''}
}
// GroupCampMemberListScope 大本营群成员列表范围
type GroupCampMemberListScope = "" | "member" | "admin" | "owner" | "quit" | "shut_up"

const GroupCampMemberListScopeCons: C<'All' | 'Member' | 'Admin' | 'Owner' | 'Quit' | 'ShutUp', GroupCampMemberListScope> = {
	All: {label: '全部', value: "", hint: ''},
	Member: {label: '群成员', value: "member", hint: ''},
	Admin: {label: '群管理员', value: "admin", hint: ''},
	Owner: {label: '群主', value: "owner", hint: ''},
	Quit: {label: '退出用户', value: "quit", hint: ''},
	ShutUp: {label: '禁言用户', value: "shut_up", hint: ''}
}

// GroupCampMemberListSortBy 大本营群成员列表可排序的字段
type GroupCampMemberListSortBy =
	"create_time"
	| "update_time"
	| "delete_time"
	| "suspend_expire_time"
	| "join_time"
	| "quit_time"
	| "shut_up_expire_time"

const GroupCampMemberListSortByCons: C<'CreateTime' | 'UpdateTime' | 'DeleteTime' | 'SuspendExpireTime' | 'JoinTime' | 'QuitTime' | 'ShutUpExpireTime', GroupCampMemberListSortBy> = {
	CreateTime: {label: '创建时间', value: "create_time", hint: ''},
	UpdateTime: {label: '更新时间', value: "update_time", hint: ''},
	DeleteTime: {label: '删除时间', value: "delete_time", hint: ''},
	SuspendExpireTime: {label: '封禁截止时间', value: "suspend_expire_time", hint: ''},
	JoinTime: {label: '加入时间', value: "join_time", hint: ''},
	QuitTime: {label: '退出时间', value: "quit_time", hint: ''},
	ShutUpExpireTime: {label: '禁言截止时间', value: "shut_up_expire_time", hint: ''}
}

// GroupBattlePlayerRole 对线群玩家角色
// 群玩家在群聊中的角色
type GroupBattlePlayerRole = 21|22|23

const GroupBattlePlayerRoleCons: C<'Founder'|'Cofounder'|'Participant', GroupBattlePlayerRole> = {
	Founder: {label: '创建者', value: 21, hint: ''},
	Cofounder: {label: '启动者', value: 22, hint: ''},
	Participant: {label: '参与者', value: 23, hint: ''}
}

// GroupTeamSide 群战队方
type GroupTeamSide = 0|1|2

const GroupTeamSideCons: C<'Onlook'|'Red'|'Blue', GroupTeamSide> = {
	Onlook: {label: '围观', value: 0, hint: ''},
	Red: {label: '红方', value: 1, hint: ''},
	Blue: {label: '蓝方', value: 2, hint: ''}
}

// GroupTencentMemberRole 腾讯云群成员角色
// 群成员在群聊中的角色
type GroupTencentMemberRole = "Owner" | "Admin" | "Member"

const GroupTencentMemberRoleCons: C<'Owner' | 'Admin' | 'Member', GroupTencentMemberRole> = {
	Owner: {label: '群主', value: "Owner", hint: ''},
	Admin: {label: '群管理员', value: "Admin", hint: ''},
	Member: {label: '群成员', value: "Member", hint: ''}
}
// GroupCampMemberRole 大本营群成员角色
// 群成员在群聊中的角色
type GroupCampMemberRole = 11|12|13

const GroupCampMemberRoleCons: C<'Member'|'Admin'|'Owner', GroupCampMemberRole> = {
	Member: {label: '群成员', value: 11, hint: ''},
	Admin: {label: '群管理员', value: 12, hint: ''},
	Owner: {label: '群主', value: 13, hint: ''}
}

// GroupFileType 群文件类型
// 群聊中上传的文件分类
type GroupFileType = "others" | "image" | "sound" | "video" | "text" | "word" | "excel" | "ppt"

const GroupFileTypeCons: C<'Others' | 'Image' | 'Sound' | 'Video' | 'Text' | 'Word' | 'Excel' | 'Ppt', GroupFileType> = {
	Others: {label: '其他', value: "others", hint: '不属于以下列举类型的文件如：apk exe dmg...'},
	Image: {label: '图片', value: "image", hint: 'png jpg gif webp raw...'},
	Sound: {label: '音频', value: "sound", hint: 'wav mp3...'},
	Video: {label: '视频', value: "video", hint: 'mp4 mov avi...'},
	Text: {label: '文本', value: "text", hint: 'txt'},
	Word: {label: 'word', value: "word", hint: 'doc docx...'},
	Excel: {label: 'excel', value: "excel", hint: 'xls xlsx...'},
	Ppt: {label: 'ppt', value: "ppt", hint: 'ppt pptx...'}
}
// GroupType 群类型
type GroupType = 1 | 2

const GroupTypeCons: C<'Camp' | 'Battle', GroupType> = {
	Camp: {label: '大本营', value: 1, hint: ''},
	Battle: {label: '对线群', value: 2, hint: ''}
}
// GroupBattleListScope 对线群范围
type GroupBattleListScope = ""|"suspended"|"prepare"|"battle"|"settle"|"finish"|"recommend"

const GroupBattleListScopeCons: C<'All'|'Suspended'|'Prepare'|'Battle'|'Settle'|'Finish'|'Recommend', GroupBattleListScope> = {
	All: {label: '全部群组', value: "", hint: ''},
	Suspended: {label: '封禁群组', value: "suspended", hint: ''},
	Prepare: {label: '准备中', value: "prepare", hint: ''},
	Battle: {label: '对线中', value: "battle", hint: ''},
	Settle: {label: '结算中', value: "settle", hint: ''},
	Finish: {label: '已结束', value: "finish", hint: ''},
	Recommend: {label: '推荐', value: "recommend", hint: ''}
}

// GroupBattleStage 对线群生命周期阶段
type GroupBattleStage = 0|1|2|3|4

const GroupBattleStageCons: C<'Unknown'|'Prepare'|'Battle'|'Settle'|'Finish', GroupBattleStage> = {
	Unknown: {label: '未知', value: 0, hint: ''},
	Prepare: {label: '准备对线', value: 1, hint: ''},
	Battle: {label: '正在对线', value: 2, hint: ''},
	Settle: {label: '对线结算', value: 3, hint: ''},
	Finish: {label: '对线结束', value: 4, hint: ''}
}
// GroupBattleListSearchKey 对线群列表可搜索的字段
type GroupBattleListSearchKey = "q" | "team_name" | "id" | "sid"

const GroupBattleListSearchKeyCons: C<'Q' | 'TeamName' | 'Id' | 'Sid', GroupBattleListSearchKey> = {
	Q: {label: '搜索', value: "q", hint: ''},
	TeamName: {label: '战队名称', value: "team_name", hint: ''},
	Id: {label: '群ID', value: "id", hint: ''},
	Sid: {label: '群散弹号', value: "sid", hint: ''}
}
// GroupCampListSearchKey 大本营列表可搜索的字段
type GroupCampListSearchKey = "q" | "name" | "id" | "sid"

const GroupCampListSearchKeyCons: C<'Q' | 'Name' | 'Id' | 'Sid', GroupCampListSearchKey> = {
	Q: {label: '搜索', value: "q", hint: ''},
	Name: {label: '群名称', value: "name", hint: ''},
	Id: {label: '群ID', value: "id", hint: ''},
	Sid: {label: '群散弹号', value: "sid", hint: ''}
}
// GroupCampListSortBy 大本营群列表可排序的字段
type GroupCampListSortBy = "create_time"|"update_time"|"delete_time"|"suspend_expire_time"|"member_count"|"quit_count"|"recommend_weight"

const GroupCampListSortByCons: C<'CreateTime'|'UpdateTime'|'DeleteTime'|'SuspendExpireTime'|'MemberCount'|'QuitCount'|'RecommendWeight', GroupCampListSortBy> = {
	CreateTime: {label: '创建时间', value: "create_time", hint: ''},
	UpdateTime: {label: '更新时间', value: "update_time", hint: ''},
	DeleteTime: {label: '删除时间', value: "delete_time", hint: ''},
	SuspendExpireTime: {label: '封禁截止时间', value: "suspend_expire_time", hint: ''},
	MemberCount: {label: '群成员数', value: "member_count", hint: ''},
	QuitCount: {label: '退出的群用户数', value: "quit_count", hint: ''},
	RecommendWeight: {label: '推荐权重', value: "recommend_weight", hint: ''}
}
// GroupBattleListSortBy 对线群列表可排序的字段
type GroupBattleListSortBy = "create_time"|"update_time"|"delete_time"|"suspend_expire_time"|"member_count"|"onlooker_count"|"quit_count"|"player_count"|"recommend_weight"|"restart_round"

const GroupBattleListSortByCons: C<'CreateTime'|'UpdateTime'|'DeleteTime'|'SuspendExpireTime'|'MemberCount'|'OnlookerCount'|'QuitCount'|'PlayerCount'|'RecommendWeight'|'RestartRound', GroupBattleListSortBy> = {
	CreateTime: {label: '创建时间', value: "create_time", hint: ''},
	UpdateTime: {label: '更新时间', value: "update_time", hint: ''},
	DeleteTime: {label: '删除时间', value: "delete_time", hint: ''},
	SuspendExpireTime: {label: '封禁截止时间', value: "suspend_expire_time", hint: ''},
	MemberCount: {label: '群成员数', value: "member_count", hint: ''},
	OnlookerCount: {label: '围观用户数', value: "onlooker_count", hint: ''},
	QuitCount: {label: '退出的群用户数', value: "quit_count", hint: ''},
	PlayerCount: {label: '玩家数', value: "player_count", hint: 'player_count = member_count - onlooker_count'},
	RecommendWeight: {label: '推荐权重', value: "recommend_weight", hint: ''},
	RestartRound: {label: '重启回合', value: "restart_round", hint: ''}
}

// GroupBattleMemberListSearchKey 对线群成员列表可搜索的字段
type GroupBattleMemberListSearchKey = "q" | "nickname" | "id" | "sid"

const GroupBattleMemberListSearchKeyCons: C<'Q' | 'Nickname' | 'Id' | 'Sid', GroupBattleMemberListSearchKey> = {
	Q: {label: '搜索', value: "q", hint: ''},
	Nickname: {label: '用户昵称', value: "nickname", hint: ''},
	Id: {label: '用户ID', value: "id", hint: ''},
	Sid: {label: '用户散弹号', value: "sid", hint: ''}
}
// GroupCampMemberListSearchKey 大本营群成员列表可搜索的字段
type GroupCampMemberListSearchKey = "q" | "nickname" | "id" | "sid"

const GroupCampMemberListSearchKeyCons: C<'Q' | 'Nickname' | 'Id' | 'Sid', GroupCampMemberListSearchKey> = {
	Q: {label: '搜索', value: "q", hint: ''},
	Nickname: {label: '用户昵称', value: "nickname", hint: ''},
	Id: {label: '用户ID', value: "id", hint: ''},
	Sid: {label: '用户散弹号', value: "sid", hint: ''}
}

// GroupBattleMessageListScope 对线群消息范围
export type GroupBattleMessageListScope = ""|"red"|"blue"|"text"|"image"|"video"|"audio"|"like"|"invite"|"notify"|"prop_use"|"prop_get"|"battle_game_point"|"achievement"|"user_event"

export const GroupBattleMessageListScopeCons: C<'All'|'Red'|'Blue'|'Text'|'Image'|'Video'|'Audio'|'Like'|'Invite'|'Notify'|'PropUse'|'PropGet'|'BattleGamePoint'|'Achievement'|'UserEvent', GroupBattleMessageListScope> = {
	All: {label: '全部', value: "", hint: ''},
	Red: {label: '红方', value: "red", hint: ''},
	Blue: {label: '蓝方', value: "blue", hint: ''},
	Text: {label: '文本', value: "text", hint: ''},
	Image: {label: '图片', value: "image", hint: ''},
	Video: {label: '视频', value: "video", hint: ''},
	Audio: {label: '语音', value: "audio", hint: ''},
	Like: {label: '点赞', value: "like", hint: ''},
	Invite: {label: '邀请', value: "invite", hint: ''},
	Notify: {label: '通知', value: "notify", hint: ''},
	PropUse: {label: '使用道具', value: "prop_use", hint: ''},
	PropGet: {label: '获得道具', value: "prop_get", hint: ''},
	BattleGamePoint: {label: '对线节点', value: "battle_game_point", hint: ''},
	Achievement: {label: '成就', value: "achievement", hint: ''},
	UserEvent: {label: '用户事件', value: "user_event", hint: ''}
}

// GroupBattleMessageListSearchKey 对线群消息列表可搜索的字段
export type GroupBattleMessageListSearchKey = "q"

export const GroupBattleMessageListSearchKeyCons: C<'Q', GroupBattleMessageListSearchKey> = {
	Q: {label: '搜索', value: "q", hint: ''}
}

// MessageChatType 消息类型
export type MessageChatType = 0|1|2|3|4|5|6|7|8|9|10|20|21|22|23|24|40|41|42

export const MessageChatTypeCons: C<'None'|'Text'|'Custom'|'Image'|'Audio'|'Video'|'File'|'Location'|'Emotion'|'Tips'|'Merger'|'PropUse'|'Like'|'Invite'|'Notify'|'PropGet'|'BattleGamePoint'|'Achievement'|'UserEvent', MessageChatType> = {
	None: {label: '未知', value: 0, hint: ''},
	Text: {label: '文本', value: 1, hint: ''},
	Custom: {label: '自定义消息', value: 2, hint: '不展示'},
	Image: {label: '图片', value: 3, hint: ''},
	Audio: {label: '语音', value: 4, hint: ''},
	Video: {label: '视频', value: 5, hint: ''},
	File: {label: '文件', value: 6, hint: ''},
	Location: {label: '地理位置', value: 7, hint: ''},
	Emotion: {label: '表情包', value: 8, hint: ''},
	Tips: {label: '提示', value: 9, hint: ''},
	Merger: {label: '合并转发', value: 10, hint: ''},
	PropUse: {label: '使用道具消息', value: 20, hint: ''},
	Like: {label: '点赞消息', value: 21, hint: ''},
	Invite: {label: '邀请消息', value: 22, hint: ''},
	Notify: {label: '通知消息', value: 23, hint: ''},
	PropGet: {label: '获得道具消息', value: 24, hint: ''},
	BattleGamePoint: {label: '对线节点', value: 40, hint: ''},
	Achievement: {label: '成就', value: 41, hint: ''},
	UserEvent: {label: '用户事件消息', value: 42, hint: '带有text是avatar_user_event\n只带有tip是user_event'}
}

// GroupCampMessageListScope 大本营消息范围
export type GroupCampMessageListScope = ""|"text"|"image"|"video"|"audio"

export const GroupCampMessageListScopeCons: C<'All'|'Text'|'Image'|'Video'|'Audio', GroupCampMessageListScope> = {
	All: {label: '全部', value: "", hint: ''},
	Text: {label: '文本', value: "text", hint: ''},
	Image: {label: '图片', value: "image", hint: ''},
	Video: {label: '视频', value: "video", hint: ''},
	Audio: {label: '语音', value: "audio", hint: ''}
}

// GroupCampMessageListSearchKey 大本营群消息列表可搜索的字段
export type GroupCampMessageListSearchKey = "q"

export const GroupCampMessageListSearchKeyCons: C<'Q', GroupCampMessageListSearchKey> = {
	Q: {label: '搜索', value: "q", hint: ''}
}


// GroupCampApplyJoinListScope 大本申请加入列表范围
export type GroupCampApplyJoinListScope = ""|"none"|"confirm"|"deny"

export const GroupCampApplyJoinListScopeCons: C<'All'|'None'|'Confirm'|'Deny', GroupCampApplyJoinListScope> = {
	All: {label: '全部', value: "", hint: ''},
	None: {label: '未处理', value: "none", hint: ''},
	Confirm: {label: '同意', value: "confirm", hint: ''},
	Deny: {label: '拒绝', value: "deny", hint: ''}
}


// GroupCampApplyJoinListSearchKey 大本营申请加入列表可搜索的字段
export type GroupCampApplyJoinListSearchKey = "q"

export const GroupCampApplyJoinListSearchKeyCons: C<'Q', GroupCampApplyJoinListSearchKey> = {
	Q: {label: '搜索', value: "q", hint: ''}
}

// GroupCampApplyJoinOption 大本营加群申请选项
export type GroupCampApplyJoinOption = 0|1

export const GroupCampApplyJoinOptionCons: C<'Audit'|'AnswerQuestion', GroupCampApplyJoinOption> = {
	Audit: {label: '需要发送验证消息', value: 0, hint: ''},
	AnswerQuestion: {label: '需要回答问题并由管理员审核', value: 1, hint: ''}
}

// GroupBattleVictoryState 群胜负状态
export type GroupBattleVictoryState = 0|1|2|3|4|5

export const GroupBattleVictoryStateCons: C<'Unknown'|'Red'|'Blue'|'Draw'|'WinWin'|'Invalid', GroupBattleVictoryState> = {
	Unknown: {label: '未知', value: 0, hint: ''},
	Red: {label: '红方获胜', value: 1, hint: ''},
	Blue: {label: '蓝方获胜', value: 2, hint: ''},
	Draw: {label: '平局', value: 3, hint: ''},
	WinWin: {label: '双赢', value: 4, hint: '双方都赢了\n特指双方结算时都是3塔'},
	Invalid: {label: '无效对局', value: 5, hint: ''}
}

export {
	GroupCampListScopeCons,
	GroupBattlePlayerRoleCons,
	GroupTeamSideCons,
	GroupTencentMemberRoleCons,
	GroupCampMemberRoleCons,
	GroupFileTypeCons,
	GroupTypeCons,
	GroupBattleListScopeCons,
	GroupBattleStageCons,
	GroupBattleListSearchKeyCons,
	GroupCampListSearchKeyCons,
	GroupCampListSortByCons,
	GroupBattleListSortByCons,
	GroupBattleMemberListScopeCons,
	GroupBattleMemberListSortByCons,
	GroupCampMemberListScopeCons,
	GroupCampMemberListSortByCons,
	GroupCampMemberListSearchKeyCons,
	GroupBattleMemberListSearchKeyCons,
}

export type {
	GroupCampListScope,
	GroupBattlePlayerRole,
	GroupTeamSide,
	GroupTencentMemberRole,
	GroupCampMemberRole,
	GroupFileType,
	GroupType,
	GroupBattleListScope,
	GroupBattleStage,
	GroupBattleListSearchKey,
	GroupCampListSearchKey,
	GroupCampListSortBy,
	GroupBattleListSortBy,
	GroupBattleMemberListScope,
	GroupBattleMemberListSortBy,
	GroupCampMemberListSortBy,
	GroupCampMemberListScope,
	GroupCampMemberListSearchKey,
	GroupBattleMemberListSearchKey
}
