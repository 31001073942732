/**
 * Created by wangtong on 2022/2/4
 */
import {C} from "@/constants/base";

// OfficialWebsiteAction 官网行为
export type OfficialWebsiteAction = "download" | "upgrade" | "share_group_camp" | "share_dynamic" | "share_group_battle"

export const OfficialWebsiteActionCons: C<'Download' | 'Upgrade' | 'ShareGroupCamp' | 'ShareDynamic' | 'ShareGroupBattle', OfficialWebsiteAction> = {
	Download: {label: '下载App', value: "download", hint: ''},
	Upgrade: {label: '更新App', value: "upgrade", hint: ''},
	ShareGroupCamp: {label: '分享大本营', value: "share_group_camp", hint: ''},
	ShareDynamic: {label: '分享动态', value: "share_dynamic", hint: ''},
	ShareGroupBattle: {label: '分享对线群', value: "share_group_battle", hint: ''}
}
// ImageStyle 图片处理规则
export type ImageStyle =
	"size_16"
	| "size_32"
	| "size_48"
	| "size_60"
	| "size_90"
	| "size_120"
	| "size_180"
	| "size_360"
	| "size_720"
	| "size_1440"
	| "blur"

export const ImageStyleCons: C<'Size16' | 'Size32' | 'Size48' | 'Size60' | 'Size90' | 'Size120' | 'Size180' | 'Size360' | 'Size720' | 'Size1440' | 'Blur', ImageStyle> = {
	Size16: {label: 'size_16', value: "size_16", hint: ''},
	Size32: {label: 'size_32', value: "size_32", hint: ''},
	Size48: {label: 'size_48', value: "size_48", hint: ''},
	Size60: {label: 'size_60', value: "size_60", hint: ''},
	Size90: {label: 'size_90', value: "size_90", hint: ''},
	Size120: {label: 'size_120', value: "size_120", hint: ''},
	Size180: {label: 'size_180', value: "size_180", hint: ''},
	Size360: {label: 'size_360', value: "size_360", hint: ''},
	Size720: {label: 'size_720', value: "size_720", hint: ''},
	Size1440: {label: 'size_1440', value: "size_1440", hint: ''},
	Blur: {label: '模糊', value: "blur", hint: ''}
}

// OfficialWebsiteDownloadButtonType 官网下载按钮
export type OfficialWebsiteDownloadButtonType = "android" | "ios"

export const OfficialWebsiteDownloadButtonTypeCons: C<'Android' | 'Ios', OfficialWebsiteDownloadButtonType> = {
	Android: {label: '安卓', value: "android", hint: ''},
	Ios: {label: 'IOS', value: "ios", hint: ''}
}

export * from './user'
export * from './common'
export * from './dynamic_comment'
export * from './dynamic'
export * from './group'
export * from './world'
export * from './global'
export * from './tag'
export * from './tag_group'
export * from './robot'
export * from './dynamic_reply'
export * from './setting'
export * from './message'
export * from './stats'

export {};
