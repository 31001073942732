/**
 * Created by wangtong on 2022/2/4
 */
import Vue from "vue";
import {CommonApi} from "@/api/common";
import {Convert} from "@sandan/febasic";
import {OfficialWebsiteQuery} from "@/models";
import {PlatformCons} from "@/constants";
import * as config from "@/config"

export default class EventUseCase {
	private vm!: Vue
	
	constructor(vm: Vue) {
		this.vm = vm
	}
	
	get isPc(): boolean {
		return this.vm.$vuetify.breakpoint.mdAndUp
	}
	
	
	private getPlatform(isIos: boolean): number {
		if (isIos) {
			if (config.Device.is_ipad) {
				return PlatformCons.Ipad.value
			} else {
				return PlatformCons.Iphone.value
			}
		} else {
			return PlatformCons.Android.value
		}
	}
	
};
