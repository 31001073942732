















































/**
 * Created by wangtong on 2022/2/4
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import * as config from "@/config"
import {PcImages} from "@/config";
import {Browser, Convert} from "@sandan/febasic";
import {OfficialWebsiteQuery} from "@/models";
import EventUseCase from "@/usecase/EventUseCase";
import {OfficialWebsiteDownloadButtonTypeCons} from "@/constants";
import BaseAppDownloader from "@/components/app_downloader/BaseAppDownloader";

@Component({})
export default class PcAppDownloader extends BaseAppDownloader {
    qrcode_dialog = {
        visible: false,
        title: "",
        qrcode: "",
        ios: false
    }

    onClickAndroid() {
        if (this.copyText) {
            Browser.copyToClipboard(Convert.parseString(this.copyText))
        }
        this.qrcode_dialog = {
            visible: true,
            title: "扫码下载安卓端散弹",
            qrcode: config.PcImages.android_qr_code,
            ios: false
        }
    }

    onClickIOS() {
        if (this.copyText) {
            Browser.copyToClipboard(Convert.parseString(this.copyText))
        }

        if (config.IOS_PUBLISHED) {
            // window.location.href = config.IOS_APP_STORE;

            this.qrcode_dialog = {
                visible: true,
                title: "扫码下载IOS端散弹",
                qrcode: config.PcImages.ios_qr_code,
                ios: true
            }
        } else {
            this.alert.visible = true
            this.alert.message = config.IOS_TOAST_MESSAGE
        }
    }

    downloadApk() {
        window.location.href = config.ANDROID_APP_APK
    }

    jumpIOSAppstore() {
        window.location.href = config.IOS_APP_STORE
    }
}
