









































/**
 * Created by wangtong on 2022/2/4
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import * as config from "@/config";
import {OfficialWebsiteActionCons, OfficialWebsiteDownloadButtonTypeCons} from "@/constants";
import {Browser, Convert, Type} from "@sandan/febasic";
import is = Type.is;
import {OfficialWebsiteQuery} from "@/models";
import {CommonApi} from "@/api/common";
import EventUseCase from "@/usecase/EventUseCase";
import BaseAppDownloader from "@/components/app_downloader/BaseAppDownloader";

@Component({})
export default class MobileAppDownloader extends BaseAppDownloader {
    get browser(): string {
        return config.Device.is_ios ? "Safari" : "浏览器";
    }

    mounted() {
        switch (this.action) {
            case OfficialWebsiteActionCons.Upgrade.value:
            case OfficialWebsiteActionCons.Download.value:
                // 下载App
                this.openOrDownloadApp()
                break
        }

        document.addEventListener("visibilitychange", this.onVisibilityChange)
    }

    destroyed() {
        document.removeEventListener("visibilitychange", this.onVisibilityChange)
    }

    onClickAndroid() {
        if (this.copyText) {
            Browser.copyToClipboard(Convert.parseString(this.copyText))
        }
        this.openOrDownloadApp(false)
    }

    onClickIOS() {
        if (this.copyText) {
            Browser.copyToClipboard(Convert.parseString(this.copyText))
        }
        this.openOrDownloadApp(true)
    }

    showIOSNotPublishedMessage() {
        this.alert.visible = true
        this.alert.message = config.IOS_TOAST_MESSAGE
    }

    showTip() {
        this.is_show_tip = true;
    }

    openOrDownloadApp(isIos: boolean = config.Device.is_ios) {
        if (isIos) {
            if (config.IOS_PUBLISHED) {
                if (config.Device.is_android_black_app) {
                    this.showTip();
                } else if (this.action == OfficialWebsiteActionCons.Upgrade.value || this.androidAppSchema == config.ANDROID_APP_SCHEMA) {
                    this.downloadApp(isIos);
                } else {
                    this.tryOpenApp(isIos);
                }
            } else {
                this.showIOSNotPublishedMessage()
            }
        } else {
            if (config.Device.is_android_black_app) {
                this.showTip();
            } else if (this.action == OfficialWebsiteActionCons.Upgrade.value || this.androidAppSchema == config.ANDROID_APP_SCHEMA) {
                this.downloadApp(isIos);
            } else {
                this.tryOpenApp(isIos);
            }
        }
    }

    openAppTimer: number | null = null
    openAppIsIos: boolean = false
    openAppSchema: string = ''

    onVisibilityChange() {
        if (document.visibilityState == 'hidden') {
            // 页面变为不可见
            if (this.openAppTimer) {
                this.stopOpenApp()
            }
        } else {
            // 页面变为可见
            this.stopOpenApp()
        }
    }

    // get androidAppSchema(): string {
    //     if (this.query) {
    //         switch (this.query.action) {
    //             case OfficialWebsiteActionCons.ShareDynamic.value:
    //                 return `sandan:///app/dynamic/detail?dynamicId=${this.query.share_dynamic_id}&mode=recommend`
    //             case OfficialWebsiteActionCons.ShareGroupBattle.value:
    //                 return `sandan:///app/group/battle/chat?groupId=${this.query.share_group_id}`
    //             case OfficialWebsiteActionCons.ShareGroupCamp.value:
    //                 return `sandan:///app/group/camp/chat?groupId=${this.query.share_group_id}`
    //         }
    //     }
    //     return config.ANDROID_APP_SCHEMA
    // }

    stopOpenApp() {
        if (this.openAppTimer) {
            clearTimeout(this.openAppTimer);
            this.openAppTimer = null
        }
    }

    tryOpenApp(isIos: boolean = config.Device.is_ios) {

        // 尝试跳转到本地的App，如果一定时间后没有跳转成功，则认为本地没有该App
        this.stopOpenApp()
        this.openAppIsIos = isIos

        this.openAppTimer = setTimeout(() => {
            this.stopOpenApp()
            if (!document.hidden) {
                // 本地没有对应的App
                this.downloadApp(isIos)
            }
        }, 2500);

        if (isIos) {
            this.openAppSchema = this.iosAppSchema;
        } else {
            this.openAppSchema = this.androidAppSchema;
        }
        window.location.href = this.openAppSchema
    }

    // 下载App，或跳转到AppStore
    downloadApp(isIos: boolean = config.Device.is_ios) {
        if (isIos) {
            // 跳转到AppStore
            window.location.href = config.IOS_APP_STORE;
        } else {
            // 本地下载Apk
            this.alert.visible = true
            this.alert.message = "正在下载APK中"
            window.location.href = config.ANDROID_APP_APK;
        }
    }
}
