/**
 * Created by wangtong on 2022/2/7
 */
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import * as config from "@/config";
import EventUseCase from "@/usecase/EventUseCase";

export default class BaseAppDownloader extends Vue{
	@Prop() action?: any
	@Prop() copyText?: string
	
	@Prop({type: String, required: false, default: config.ANDROID_APP_SCHEMA}) androidAppSchema!: string
	@Prop({type: String, required: false, default: config.IOS_APP_SCHEMA}) iosAppSchema!: string
	
	MobileImages = config.MobileImages
	PcImages = config.PcImages
	
	Device = config.Device
	
	useCase = new EventUseCase(this)
	
	is_show_tip = false
	
	alert = {
		visible: false,
		title: "",
		message: ""
	}
	
};
