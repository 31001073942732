/**
 * Created by wangtong on 2022/2/4
 */
import Vue from "vue";
import {CommonVisitOfficialRequest, OfficialWebsiteQuery} from "@/models";
import {CommonApi} from "@/api/common";
import {Device} from "@/config";
import {Platform, PlatformCons} from "@/constants";

export default class BasePage extends Vue {
	
	get isPc(): boolean {
		return this.$vuetify.breakpoint.mdAndUp
	}
	
	get query(): OfficialWebsiteQuery {
		return this.$route.query
	}
	
	created() {
		let platform: Platform = PlatformCons.Pc.value
		if (this.isPc) {
			if (Device.is_ipad) {
				platform = PlatformCons.Ipad.value
			} else if (Device.is_ios) {
				platform = PlatformCons.Mac.value
			} else {
				platform = PlatformCons.Pc.value
			}
		} else {
			if (Device.is_ios) {
				platform = PlatformCons.Iphone.value
			} else {
				platform = PlatformCons.Android.value
			}
		}
		
		let request: CommonVisitOfficialRequest = {
			action: this.query.action,
			from: this.query.from,
			from_user_id: this.query.from_user_id,
			user_agent: navigator.userAgent,
			website_is_pc: this.isPc ? 1 : 0,
			website_platform: platform,
			website_screen_height: window.screen.height,
			website_screen_width: window.screen.width,
			website_version: process.env.VUE_APP_VERSION,
			website_version_name: process.env.VUE_APP_VERSION_NAME,
		}
		
		CommonApi.visitOfficial(request)
	}
};
