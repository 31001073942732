/**
 * Created by wangtong on 2021/5/21
 */
import {C} from "./base"
// UserGender 用户性别
// 用户的性别
type UserGender = 0 | 1 | 2

const UserGenderCons: C<'Unknown' | 'Girl' | 'Boy', UserGender> = {
	Unknown: {label: '未知', value: 0, hint: ''},
	Girl: {label: '女生', value: 1, hint: ''},
	Boy: {label: '男生', value: 2, hint: ''}
}
// UserListSortBy 用户列表可排序的字段
type UserListSortBy = "birthday"|"create_time"|"update_time"|"delete_time"|"suspend_expire_time"|"follow_count"|"fan_count"|"friend_count"|"like_dynamic_count"|"like_dynamic_comment_count"|"create_dynamic_count"|"create_dynamic_comment_count"|"create_world_count"|"create_group_camp_count"|"create_group_battle_count"|"join_world_count"|"join_group_camp_count"|"join_group_battle_count"|"follow_time"|"keyboard_points"|"level"|"rank"|"star"|"last_active_time"

const UserListSortByCons: C<'Birthday'|'CreateTime'|'UpdateTime'|'DeleteTime'|'SuspendExpireTime'|'FollowCount'|'FanCount'|'FriendCount'|'LikeDynamicCount'|'LikeDynamicCommentCount'|'CreateDynamicCount'|'CreateDynamicCommentCount'|'CreateWorldCount'|'CreateGroupCampCount'|'CreateGroupBattleCount'|'JoinWorldCount'|'JoinGroupCampCount'|'JoinGroupBattleCount'|'FollowTime'|'KeyboardPoints'|'Level'|'Rank'|'Star'|'LastActiveTime', UserListSortBy> = {
	Birthday: {label: '生日', value: "birthday", hint: ''},
	CreateTime: {label: '创建时间', value: "create_time", hint: ''},
	UpdateTime: {label: '更新时间', value: "update_time", hint: ''},
	DeleteTime: {label: '删除时间', value: "delete_time", hint: ''},
	SuspendExpireTime: {label: '封禁截止时间', value: "suspend_expire_time", hint: ''},
	FollowCount: {label: '关注数', value: "follow_count", hint: ''},
	FanCount: {label: '粉丝数', value: "fan_count", hint: ''},
	FriendCount: {label: '好友数', value: "friend_count", hint: ''},
	LikeDynamicCount: {label: '点赞动态数', value: "like_dynamic_count", hint: ''},
	LikeDynamicCommentCount: {label: '点赞动态评论数', value: "like_dynamic_comment_count", hint: ''},
	CreateDynamicCount: {label: '创建动态数', value: "create_dynamic_count", hint: ''},
	CreateDynamicCommentCount: {label: '创建动态评论数', value: "create_dynamic_comment_count", hint: ''},
	CreateWorldCount: {label: '创建世界数', value: "create_world_count", hint: ''},
	CreateGroupCampCount: {label: '创建大本营数', value: "create_group_camp_count", hint: ''},
	CreateGroupBattleCount: {label: '创建对线群数', value: "create_group_battle_count", hint: ''},
	JoinWorldCount: {label: '加入世界数', value: "join_world_count", hint: ''},
	JoinGroupCampCount: {label: '加入大本营数', value: "join_group_camp_count", hint: ''},
	JoinGroupBattleCount: {label: '加入对线群数', value: "join_group_battle_count", hint: ''},
	FollowTime: {label: '关注时间', value: "follow_time", hint: ''},
	KeyboardPoints: {label: '键盘积分', value: "keyboard_points", hint: ''},
	Level: {label: '段位', value: "level", hint: ''},
	Rank: {label: '等级', value: "rank", hint: ''},
	Star: {label: '星数', value: "star", hint: ''},
	LastActiveTime: {label: '最近活跃时间', value: "last_active_time", hint: ''}
}

// UserSendCaptchaSource 发送验证码来源
type UserSendCaptchaSource = 1 | 2 | 3

const UserSendCaptchaSourceCons: C<'LoginRegister' | 'OldPhoneUnbind' | 'NewPhoneBind', UserSendCaptchaSource> = {
	LoginRegister: {label: '登陆注册', value: 1, hint: ''},
	OldPhoneUnbind: {label: '旧手机号解绑验证', value: 2, hint: ''},
	NewPhoneBind: {label: '新手机号绑定验证', value: 3, hint: ''}
}
// UserAchievementLevel 用户成就等级
type UserAchievementLevel = 0 | 1 | 2 | 3

const UserAchievementLevelCons: C<'Zero' | 'One' | 'Two' | 'Three', UserAchievementLevel> = {
	Zero: {label: '0段', value: 0, hint: ''},
	One: {label: '1段', value: 1, hint: ''},
	Two: {label: '2段', value: 2, hint: ''},
	Three: {label: '3段', value: 3, hint: ''}
}
// UserAchievementType 用户成就类型
// 用户所有可达成的成就类型
type UserAchievementType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16

const UserAchievementTypeCons: C<'GoldenKeyboard' | 'WenquStar' | 'Pianist' | 'ThumpKing' | 'Founder' | 'CoFounder' | 'BattleMonster' | 'GiftMaster' | 'TurnUpsideDown' | 'AlwaysWin' | 'NeverGiveUp' | 'WinRepeatedly' | 'LoseRepeatedly' | 'Famous' | 'Social' | 'LoginRepeatedly', UserAchievementType> = {
	GoldenKeyboard: {label: '金键盘', value: 1, hint: ''},
	WenquStar: {label: '文曲星', value: 2, hint: ''},
	Pianist: {label: '钢琴家', value: 3, hint: ''},
	ThumpKing: {label: '暴击王', value: 4, hint: ''},
	Founder: {label: '操盘手', value: 5, hint: ''},
	CoFounder: {label: '三人成众', value: 6, hint: ''},
	BattleMonster: {label: '对线怪', value: 7, hint: ''},
	GiftMaster: {label: '道具专家', value: 8, hint: ''},
	TurnUpsideDown: {label: '逆风翻盘', value: 9, hint: ''},
	AlwaysWin: {label: '常胜将军', value: 10, hint: ''},
	NeverGiveUp: {label: '永不气馁', value: 11, hint: ''},
	WinRepeatedly: {label: '连胜', value: 12, hint: ''},
	LoseRepeatedly: {label: '连败', value: 13, hint: ''},
	Famous: {label: '知名人物', value: 14, hint: ''},
	Social: {label: '扩列达人', value: 15, hint: ''},
	LoginRepeatedly: {label: '连续登录', value: 16, hint: ''}
}
// UserDivisionLevel 用户段位层次
// 用户的大段位
type UserDivisionLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7

const UserDivisionLevelCons: C<'Unknown' | 'Bronze' | 'Silver' | 'Gold' | 'Platinum' | 'Diamond' | 'Starlight' | 'Challenger', UserDivisionLevel> = {
	Unknown: {label: '未知', value: 0, hint: ''},
	Bronze: {label: '青铜', value: 1, hint: ''},
	Silver: {label: '白银', value: 2, hint: ''},
	Gold: {label: '黄金', value: 3, hint: ''},
	Platinum: {label: '铂金', value: 4, hint: ''},
	Diamond: {label: '钻石', value: 5, hint: ''},
	Starlight: {label: '星耀', value: 6, hint: ''},
	Challenger: {label: '最强王者', value: 7, hint: ''}
}
// UserDivisionRank 用户段位等级
// 段位等级没有0
type UserDivisionRank = 0 | 1 | 2 | 3 | 4 | 5 | 6

const UserDivisionRankCons: C<'Zero' | 'One' | 'Two' | 'Three' | 'Four' | 'Five' | 'Challenger', UserDivisionRank> = {
	Zero: {label: '等级0', value: 0, hint: ''},
	One: {label: '等级1', value: 1, hint: ''},
	Two: {label: '等级2', value: 2, hint: ''},
	Three: {label: '等级3', value: 3, hint: ''},
	Four: {label: '等级4', value: 4, hint: ''},
	Five: {label: '等级5', value: 5, hint: ''},
	Challenger: {label: '最强王者', value: 6, hint: ''}
}

// UserLikeType 用户点赞类型
type UserLikeType = 0 | 1 | 2

const UserLikeTypeCons: C<'Cancel' | 'Like' | 'Dislike', UserLikeType> = {
	Cancel: {label: '取消', value: 0, hint: ''},
	Like: {label: '点赞', value: 1, hint: ''},
	Dislike: {label: '点踩', value: 2, hint: ''}
}
// UserListScope 用户列表范围
type UserListScope = ""|"real"|"robot"|"my_robot"|"suspended"|"online"

const UserListScopeCons: C<'All'|'Real'|'Robot'|'MyRobot'|'Suspended'|'Online', UserListScope> = {
	All: {label: '全部用户', value: "", hint: ''},
	Real: {label: '弹友', value: "real", hint: ''},
	Robot: {label: '马甲号', value: "robot", hint: ''},
	MyRobot: {label: '我的马甲号', value: "my_robot", hint: ''},
	Suspended: {label: '封禁用户', value: "suspended", hint: ''},
	Online: {label: '在线用户', value: "online", hint: ''}
}


// UserBelongToManagerRole 用户属于管理员角色
type UserBelongToManagerRole = 1 | 2 | 3

const UserBelongToManagerRoleCons: C<'Owner' | 'Admin' | 'Hold', UserBelongToManagerRole> = {
	Owner: {label: '号主', value: 1, hint: '最高级持有\n可以修改马甲号的信息\n手机端马甲号登录发给该管理员验证码\n可以操作'},
	Admin: {label: '管理', value: 2, hint: '管理\n可以修改马甲号信息'},
	Hold: {label: '普通持有', value: 3, hint: '普通持有\n可以用该马甲号发动态、群聊消息\n可以在后台看到马甲号的验证码'}
}

// UserAchievementGroupType 用户成就分组类型
// 用户所有可达成的成就类型
type UserAchievementGroupType = 0|1

const UserAchievementGroupTypeCons: C<'Battle'|'Active', UserAchievementGroupType> = {
	Battle: {label: '战斗成就', value: 0, hint: ''},
	Active: {label: '活跃成就', value: 1, hint: ''}
}
// UserBattleVictoryState 用户对线胜利状态
// 只有失败、胜利、平局才会展示在用户战绩里
type UserBattleVictoryState = 0|1|2|3|4

const UserBattleVictoryStateCons: C<'Unknown'|'Win'|'Lose'|'Draw'|'Invalid', UserBattleVictoryState> = {
	Unknown: {label: '未知', value: 0, hint: '还没有结束对线'},
	Win: {label: '胜利', value: 1, hint: ''},
	Lose: {label: '失败', value: 2, hint: ''},
	Draw: {label: '平局', value: 3, hint: ''},
	Invalid: {label: '无效对局', value: 4, hint: '双方均没有获得任何一个对线节点'}
}
// UserChatOption 用户私信选项
// 谁可以私信我
type UserChatOption = 0|1|2|3

const UserChatOptionCons: C<'All'|'OnlyGreet'|'OnlyFriend'|'DenyAll', UserChatOption> = {
	All: {label: '所有人', value: 0, hint: ''},
	OnlyGreet: {label: '仅打招呼', value: 1, hint: ''},
	OnlyFriend: {label: '仅好友', value: 2, hint: ''},
	DenyAll: {label: '关闭', value: 3, hint: ''}
}
// UserKeyboardPointsSource 用户键盘积分来源
type UserKeyboardPointsSource = 0|1|2|3|4|5|6|7|8|9

const UserKeyboardPointsSourceCons: C<'Bonus'|'ReachUserAchievement'|'GroupBattleResult'|'Punish'|'OpenApp'|'GroupBattleCreate'|'GroupBattlePlay'|'DynamicCreate'|'DynamicLike'|'DynamicCommentCreate', UserKeyboardPointsSource> = {
	Bonus: {label: '额外奖励', value: 0, hint: '键盘积分增加的后门'},
	ReachUserAchievement: {label: '达成用户成就', value: 1, hint: ''},
	GroupBattleResult: {label: '对线结果', value: 2, hint: ''},
	Punish: {label: '惩罚', value: 3, hint: '惩罚性扣除键盘积分'},
	OpenApp: {label: '打开App', value: 4, hint: '第n天打开App'},
	GroupBattleCreate: {label: '创建对线群', value: 5, hint: '创建对线群'},
	GroupBattlePlay: {label: '加入对线群成为玩家', value: 6, hint: '加入对线群成为玩家'},
	DynamicCreate: {label: '创建动态', value: 7, hint: '创建动态'},
	DynamicLike: {label: '点赞动态', value: 8, hint: ''},
	DynamicCommentCreate: {label: '创建动态评论', value: 9, hint: ''}
}
// UserOnlineState 用户在线状态
type UserOnlineState = 0|1|2

const UserOnlineStateCons: C<'Offline'|'PushOnline'|'Online', UserOnlineState> = {
	Offline: {label: '未登录', value: 0, hint: ''},
	PushOnline: {label: '后台运行', value: 1, hint: ''},
	Online: {label: '在线', value: 2, hint: ''}
}
// UserPropSource 用户道具来源
type UserPropSource = 0|1|2|3|4|5|6|7

const UserPropSourceCons: C<'Bonus'|'Use'|'LoginReward'|'BattleSpeakReward'|'RegisterReward'|'Compensate'|'AdInspire'|'BattleWinReward', UserPropSource> = {
	Bonus: {label: '奖励', value: 0, hint: ''},
	Use: {label: '使用', value: 1, hint: ''},
	LoginReward: {label: '登录奖励', value: 2, hint: ''},
	BattleSpeakReward: {label: '对线发言奖励', value: 3, hint: ''},
	RegisterReward: {label: '注册奖励', value: 4, hint: ''},
	Compensate: {label: '赔偿', value: 5, hint: ''},
	AdInspire: {label: '视频广告激励', value: 6, hint: ''},
	BattleWinReward: {label: '对线胜利奖励', value: 7, hint: ''}
}

export {
	UserGenderCons,
	UserListSortByCons,
	UserSendCaptchaSourceCons,
	UserAchievementLevelCons,
	UserAchievementTypeCons,
	UserDivisionLevelCons,
	UserDivisionRankCons,
	UserLikeTypeCons,
	UserListScopeCons,
	UserBelongToManagerRoleCons,
	UserAchievementGroupTypeCons,
	UserBattleVictoryStateCons,
	UserChatOptionCons,
	UserKeyboardPointsSourceCons,
	UserOnlineStateCons,
	UserPropSourceCons
}

// UserReadLevel 用户阅读级别
export type UserReadLevel = 0|1

export const UserReadLevelCons: C<'Brief'|'Detailed', UserReadLevel> = {
	Brief: {label: 'brief', value: 0, hint: '简短的，3s以上'},
	Detailed: {label: 'detailed', value: 1, hint: '详细的，10s以上'}
}
export type {
	UserGender,
	UserListSortBy,
	UserSendCaptchaSource,
	UserAchievementLevel,
	UserAchievementType,
	UserDivisionLevel,
	UserDivisionRank,
	UserLikeType,
	UserListScope,
	UserBelongToManagerRole,
	UserAchievementGroupType,
	UserBattleVictoryState,
	UserChatOption,
	UserKeyboardPointsSource,
	UserOnlineState,
	UserPropSource
}
