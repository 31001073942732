/**
 * Created by wangtong on 2021/6/22
 */
import {C} from './base'
// Platform 平台
export type Platform = 1|2|3|4|5|6|7|8

export const PlatformCons: C<'Iphone'|'Android'|'Pc'|'MiniProgram'|'Web'|'H5'|'Ipad'|'Mac', Platform> = {
	Iphone: {label: 'iPhone', value: 1, hint: ''},
	Android: {label: 'Android', value: 2, hint: ''},
	Pc: {label: 'PC端', value: 3, hint: ''},
	MiniProgram: {label: '小程序端mini_program', value: 4, hint: ''},
	Web: {label: 'web，电脑网页端', value: 5, hint: ''},
	H5: {label: 'H5，手机网页端', value: 6, hint: ''},
	Ipad: {label: 'iPad', value: 7, hint: ''},
	Mac: {label: 'Mac', value: 8, hint: ''}
}

// CookieKey cookie键
export type CookieKey =
	"app_access_token"
	| "app_uid"
	| "app_version"
	| "app_version_name"
	| "app_channel"
	| "app_platform"

export const CookieKeyCons: C<'AccessToken' | 'UID' | 'Version' | 'VersionName' | 'Channel' | 'Platform', CookieKey> = {
	AccessToken: {label: '登录令牌', value: "app_access_token", hint: ''},
	UID: {label: 'UID', value: "app_uid", hint: ''},
	Version: {label: 'App版本号', value: "app_version", hint: '13'},
	VersionName: {label: 'App版本名', value: "app_version_name", hint: '例：1.2.9'},
	Channel: {label: '渠道', value: "app_channel", hint: ''},
	Platform: {label: '平台', value: "app_platform", hint: ''}
}
// OSSDir OSS目录
export type OSSDir =
	"image/user-avatar"
	| "image/group-avatar"
	| "image/team-avatar"
	| "image/world-avatar"
	| "image/user-bg"
	| "image/group-bg"
	| "image/world-bg"
	| "image/dynamic"
	| "image/comment"
	| "image/world"
	| "image/chat"
	| "image/group"
	| "image/feedback"
	| "tmp"
	| "video/dynamic"
	| "video/group"
	| "video/chat"
	| "audio/dynamic"
	| "audio/comment"
	| "audio/group"
	| "audio/chat"
	| "image/manager-avatar"
	| "image/manager-group-avatar"

export const OSSDirCons: C<'UserAvatar' | 'GroupAvatar' | 'TeamAvatar' | 'WorldAvatar' | 'UserBg' | 'GroupBg' | 'WorldBg' | 'DynamicImage' | 'CommentImage' | 'WorldImage' | 'ChatImage' | 'GroupImage' | 'FeedbackImage' | 'Tmp' | 'DynamicVideo' | 'GroupVideo' | 'ChatVideo' | 'DynamicAudio' | 'CommentAudio' | 'GroupAudio' | 'ChatAudio' | 'ManagerAvatar' | 'ManagerGroupAvatar', OSSDir> = {
	UserAvatar: {label: '用户头像', value: "image/user-avatar", hint: ''},
	GroupAvatar: {label: '群组头像', value: "image/group-avatar", hint: ''},
	TeamAvatar: {label: '战队头像', value: "image/team-avatar", hint: ''},
	WorldAvatar: {label: '世界头像', value: "image/world-avatar", hint: ''},
	UserBg: {label: '用户背景图', value: "image/user-bg", hint: ''},
	GroupBg: {label: '群组背景图', value: "image/group-bg", hint: ''},
	WorldBg: {label: '世界背景图', value: "image/world-bg", hint: ''},
	DynamicImage: {label: '动态内容照片', value: "image/dynamic", hint: ''},
	CommentImage: {label: '评论内容照片', value: "image/comment", hint: ''},
	WorldImage: {label: '世界广场内容照片', value: "image/world", hint: ''},
	ChatImage: {label: '私信内容照片', value: "image/chat", hint: ''},
	GroupImage: {label: '群聊内容照片', value: "image/group", hint: ''},
	FeedbackImage: {label: '意见反馈照片', value: "image/feedback", hint: ''},
	Tmp: {label: '临时文件', value: "tmp", hint: ''},
	DynamicVideo: {label: '动态视频', value: "video/dynamic", hint: ''},
	GroupVideo: {label: '群组视频', value: "video/group", hint: ''},
	ChatVideo: {label: '私信视频', value: "video/chat", hint: ''},
	DynamicAudio: {label: '动态音频', value: "audio/dynamic", hint: ''},
	CommentAudio: {label: '评论音频', value: "audio/comment", hint: ''},
	GroupAudio: {label: '群聊音频', value: "audio/group", hint: ''},
	ChatAudio: {label: '私信音频', value: "audio/chat", hint: ''},
	ManagerAvatar: {label: '管理员头像', value: "image/manager-avatar", hint: ''},
	ManagerGroupAvatar: {label: '管理员组头像', value: "image/manager-group-avatar", hint: ''}
}
// ImageStyle 图片处理规则
export type ImageStyle =
	"size_16"
	| "size_32"
	| "size_48"
	| "size_60"
	| "size_90"
	| "size_120"
	| "size_180"
	| "size_360"
	| "size_720"
	| "size_1440"
	| "blur"

export const ImageStyleCons: C<'Size16' | 'Size32' | 'Size48' | 'Size60' | 'Size90' | 'Size120' | 'Size180' | 'Size360' | 'Size720' | 'Size1440' | 'Blur', ImageStyle> = {
	Size16: {label: 'size_16', value: "size_16", hint: ''},
	Size32: {label: 'size_32', value: "size_32", hint: ''},
	Size48: {label: 'size_48', value: "size_48", hint: ''},
	Size60: {label: 'size_60', value: "size_60", hint: ''},
	Size90: {label: 'size_90', value: "size_90", hint: ''},
	Size120: {label: 'size_120', value: "size_120", hint: ''},
	Size180: {label: 'size_180', value: "size_180", hint: ''},
	Size360: {label: 'size_360', value: "size_360", hint: ''},
	Size720: {label: 'size_720', value: "size_720", hint: ''},
	Size1440: {label: 'size_1440', value: "size_1440", hint: ''},
	Blur: {label: '模糊', value: "blur", hint: ''}
}

// ImageFormat 图片格式
export type ImageFormat = "jpg" | "png" | "gif" | "webp"

export const ImageFormatCons: C<'Jpg' | 'Png' | 'Gif' | 'Webp', ImageFormat> = {
	Jpg: {label: 'jpg', value: "jpg", hint: ''},
	Png: {label: 'jpg', value: "png", hint: ''},
	Gif: {label: 'gif', value: "gif", hint: ''},
	Webp: {label: 'webp', value: "webp", hint: ''}
}
// Channel 渠道
export type Channel =
	"beta"
	| "old"
	| "admin"
	| "boss"
	| "official"
	| "upgrade"
	| "huawei"
	| "xiaomi"
	| "vivo"
	| "oppo"
	| "baidu"
	| "meizu"
	| "appstore"
	| "yingyongbao"
	| "wandoujia"
	| "douyin"
	| "kuaishou"
	| "douyin_feed"
	| "douyin_star"
	| "kuaishou_feed"
	| "kuaishou_star"
	| "sandan"

export const ChannelCons: C<'Beta' | 'Old' | 'Admin' | 'Boss' | 'Official' | 'Upgrade' | 'Huawei' | 'Xiaomi' | 'Vivo' | 'Oppo' | 'Baidu' | 'Meizu' | 'Appstore' | 'Yingyongbao' | 'Wandoujia' | 'Douyin' | 'Kuaishou' | 'DouyinFeed' | 'DouyinStar' | 'KuaishouFeed' | 'KuaishouStar' | 'Sandan', Channel> = {
	Beta: {label: '内部beta测试包', value: "beta", hint: ''},
	Old: {label: '老app', value: "old", hint: ''},
	Admin: {label: '内部管理员', value: "admin", hint: ''},
	Boss: {label: '内部老板', value: "boss", hint: ''},
	Official: {label: '官网', value: "official", hint: ''},
	Upgrade: {label: '更新包', value: "upgrade", hint: ''},
	Huawei: {label: '华为', value: "huawei", hint: ''},
	Xiaomi: {label: '小米', value: "xiaomi", hint: ''},
	Vivo: {label: 'VIVO', value: "vivo", hint: ''},
	Oppo: {label: 'OPPO', value: "oppo", hint: ''},
	Baidu: {label: '百度', value: "baidu", hint: ''},
	Meizu: {label: '魅族', value: "meizu", hint: ''},
	Appstore: {label: 'IOS App store', value: "appstore", hint: ''},
	Yingyongbao: {label: '应用宝', value: "yingyongbao", hint: ''},
	Wandoujia: {label: '豌豆荚', value: "wandoujia", hint: ''},
	Douyin: {label: '抖音', value: "douyin", hint: ''},
	Kuaishou: {label: '快手', value: "kuaishou", hint: ''},
	DouyinFeed: {label: '抖音feed流', value: "douyin_feed", hint: ''},
	DouyinStar: {label: '抖音信息流', value: "douyin_star", hint: ''},
	KuaishouFeed: {label: '快手信息流', value: "kuaishou_feed", hint: ''},
	KuaishouStar: {label: '快手网红', value: "kuaishou_star", hint: ''},
	Sandan: {label: '散弹', value: "sandan", hint: ''}
}


// PropType 道具类型
export type PropType = 1|2|3

export const PropTypeCons: C<'Crit'|'Freeze'|'Weak', PropType> = {
	Crit: {label: '暴击卡', value: 1, hint: ''},
	Freeze: {label: '冰冻卡', value: 2, hint: ''},
	Weak: {label: '虚弱卡', value: 3, hint: ''}
}
// DurationUnits 时间尺度单位
export type DurationUnits = 1 | 1000 | 60000 | 3600000 | 86400000

export const DurationUnitsCons: C<'Ms' | 'S' | 'M' | 'H' | 'D', DurationUnits> = {
	Ms: {label: '毫秒', value: 1, hint: ''},
	S: {label: '秒', value: 1000, hint: ''},
	M: {label: '分钟', value: 60000, hint: ''},
	H: {label: '小时', value: 3600000, hint: ''},
	D: {label: '天', value: 86400000, hint: ''}
}

// ReportSettlementType 举报处理结果类型
export type ReportSettlementType = 0 | 1 | 2

export const ReportSettlementTypeCons: C<'None' | 'Ignore' | 'Suspend', ReportSettlementType> = {
	None: {label: '未处理', value: 0, hint: ''},
	Ignore: {label: '忽略', value: 1, hint: ''},
	Suspend: {label: '封禁', value: 2, hint: ''}
}

// ReportType 举报类型
// 可以被举报的所有行为类型
export type ReportType = 0 | 1 | 2 | 3 | 4 | 5 | 6

export const ReportTypeCons: C<'Unknown' | 'Abuse' | 'WrongSideDeliberately' | 'Advertisement' | 'Political' | 'Vice' | 'FloodScreen', ReportType> = {
	Unknown: {label: '未知', value: 0, hint: ''},
	Abuse: {label: '辱骂', value: 1, hint: ''},
	WrongSideDeliberately: {label: '反串', value: 2, hint: ''},
	Advertisement: {label: '广告', value: 3, hint: ''},
	Political: {label: '涉政', value: 4, hint: ''},
	Vice: {label: '黄赌毒', value: 5, hint: ''},
	FloodScreen: {label: '刷屏', value: 6, hint: ''}
}

// SizeUnits 大小尺度单位
export type SizeUnits = 1 | 1024 | 1048576 | 1073741824

export const SizeUnitsCons: C<'B' | 'Kb' | 'Mb' | 'Gb', SizeUnits> = {
	B: {label: 'B', value: 1, hint: ''},
	Kb: {label: 'KB', value: 1024, hint: ''},
	Mb: {label: 'MB', value: 1048576, hint: ''},
	Gb: {label: 'GB', value: 1073741824, hint: ''}
}
