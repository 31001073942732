/**
 * Created by wangtong on 2021/12/12
 */

let u = navigator.userAgent.toLowerCase();
const is_ios = u.indexOf('iphone') > -1 || u.indexOf('mac') > -1 || u.indexOf('ios') > -1 || u.indexOf("ipad") > -1 || u.indexOf("ipod") > -1 || u.indexOf("itouch") > -1;
const is_ipad = u.indexOf("ipad") > -1;

let is_android_black_app = /micromessenger/i.test(u) || u.indexOf("weibo") > -1 || u.indexOf("wechat") > -1 || u.indexOf('miniprogram') > -1;
let is_ios_black_app = /micromessenger/i.test(u) || u.indexOf("weibo") > -1 || u.indexOf("wechat") > -1 || u.indexOf('miniprogram') > -1;

let is_qq_mini = /qq/i.test(u) && /miniprogram/i.test(u); // qq小程序

export const Device = {
	is_ios,
	is_android_black_app,
	is_ios_black_app,
	is_qq_mini,
	is_ipad
}
